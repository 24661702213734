import React, { useState } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Card,
} from "react-bootstrap";

const EditProductModal = ({
  show,
  handleClose,
  product,
  updateProductDetails,
}) => {
  const handleCloseEdit = () => {
    handleClose();
    setProductSKU(product.productSKU);
    setProductName(product.productName);
    setProductPrice(product.productPrice);
    setProductColor(product.productColor);
    setProductCategory(product.productCategory);
    handleEasyIronOptionChange(product.EasyIronOption);
    setSeasonOption(product.seasonOption);
    setProductFabric(product.productFabric);
  };

  const [productSKU, setProductSKU] = useState(product.productSKU);
  const [productName, setProductName] = useState(product.productName);
  const [productPrice, setProductPrice] = useState(product.productPrice);
  const [productColor, setProductColor] = useState(product.productColor);
  const [productCategory, setProductCategory] = useState(
    product.productCategory
  );
  const [productFabric, setProductFabric] = useState(product.productFabric);
  const [productFabricPattern, setProductFabricPattern] = useState(
    product.productFabricPattern
  );
  const [EasyIronOption, handleEasyIronOptionChange] = useState(
    product.EasyIronOption
  );
  const [seasonOption, setSeasonOption] = useState(product.seasonOption);

  return (
    <Modal
      show={show}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleCloseEdit}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ width: "100%" }}>
          <InputGroup>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              type="text"
              id="product-Name"
              name="product-Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </InputGroup>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Card.Img
              className="img-square-wrapper"
              src={product.imgUrl1}
              style={{ height: "15rem", width: "15rem" }}
            />
          </Col>

          <Col>
            <Row>
              <InputGroup>
                <InputGroup.Text id="SKUChange">Product SKU</InputGroup.Text>
                <Form.Control
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  type="number"
                  id="product-SKU"
                  name="product-SKU"
                  value={productSKU}
                  onChange={(e) => setProductSKU(e.target.value)}
                />
              </InputGroup>
            </Row>
            <Row>
              <InputGroup>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Product Price
                </InputGroup.Text>
                <Form.Control
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  type="number"
                  id="product-Price"
                  name="product-Price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                />
              </InputGroup>
            </Row>

            <Row>
              <InputGroup as={Col}>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Product Color
                </InputGroup.Text>
                <Form.Select
                  value={productColor}
                  onChange={(e) => setProductColor(e.target.value)}
                  id="product-Color"
                >
                  <option value="Black">Black</option>
                  <option value="Blue">Blue</option>
                  <option value="Brown">Brown</option>
                  <option value="Cream">Cream</option>
                  <option value="Green">Green</option>
                  <option value="Grey">Grey</option>
                  <option value="Orange">Orange</option>
                  <option value="Pink">Pink</option>
                  <option value="Purple">Purple</option>
                  <option value="Red">Red</option>
                  <option value="White">White</option>
                  <option value="Yellow">Yellow</option>
                  <option value="Others">Others</option>
                </Form.Select>
              </InputGroup>
            </Row>
            <Row>
              <InputGroup as={Col}>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Product Category
                </InputGroup.Text>
                <Form.Select
                  value={productCategory}
                  onChange={(e) => setProductCategory(e.target.value)}
                  id="product-Category"
                >
                  <option value="Suiting">Suiting</option>
                  <option value="Shirting">Shirting</option>
                  <option value="Lining">Lining</option>
                  <option value="Others">Others</option>
                </Form.Select>
              </InputGroup>
            </Row>
            <Row>
              <InputGroup as={Col}>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Product Category
                </InputGroup.Text>
                <Form.Select
                  value={productFabric}
                  onChange={(e) => setProductFabric(e.target.value)}
                  id="product-Category"
                >
                  <option value="Cotton">Cotton</option>
                  <option value="Linen">Linen</option>
                  <option value="Polyester">Polyester</option>
                  <option value="Satin">Satin</option>
                  <option value="Mix">Mix</option>
                  <option value="Woven">Woven</option>
                  <option value="Others">Others</option>
                </Form.Select>
              </InputGroup>
            </Row>

            <Row>
              <InputGroup as={Col}>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Fabric Pattern
                </InputGroup.Text>
                <Form.Select
                  value={productFabricPattern}
                  onChange={(e) => setProductFabricPattern(e.target.value)}
                  id="product-Fabric-Pattern"
                >
                  <option value="Checks">Checks</option>
                  <option value="Chambray">Chambray</option>
                  <option value="Dobby">Dobby</option>
                  <option value="Jacquard">Jacquard</option>
                  <option value="Herringbone">Herringbone</option>
                  <option value="Houndstooth">Houndstooth</option>
                  <option value="Oxford">Oxford</option>
                  <option value="Pinpoint">Pinpoint</option>
                  <option value="Plain">Plain</option>
                  <option value="Poplin">Poplin</option>
                  <option value="Prints">Prints</option>
                  <option value="Stripe">Stripe</option>
                  <option value="Twill">Twill</option>
                </Form.Select>
              </InputGroup>
            </Row>

            <Row>
              <InputGroup as={Col}>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Product Season
                </InputGroup.Text>
                <Form.Select
                  value={seasonOption}
                  id="product-Season"
                  onChange={(e) => setSeasonOption(e.target.value)}
                >
                  <option value="AllSeason">All Season</option>
                  <option value="SpringSummer">Spring Summer</option>
                </Form.Select>
              </InputGroup>
            </Row>
            <Row>
              <InputGroup as={Col}>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Easy Iron
                </InputGroup.Text>
                <Form.Select
                  value={EasyIronOption}
                  id="product-Easy-Iron"
                  onChange={(e) => handleEasyIronOptionChange(e.target.value)}
                >
                  <option value="true">Yes</option>
                  <option value="false">No </option>
                </Form.Select>
              </InputGroup>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEdit}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            updateProductDetails(
              product.id,
              productSKU,
              productName,
              productPrice,
              productColor,
              productCategory,
              productFabricPattern,
              EasyIronOption,
              seasonOption,
              productFabric
            );
            handleClose();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProductModal;
