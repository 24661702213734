import { useState } from "react";
import { Card, Col, InputGroup, Row, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ImageMagnifier from "./ImageMagnifier";
import { useCart } from "../cart/Cart";
import Carousel from "react-bootstrap/Carousel";

export const Product = ({ product }) => {
  const [show, setShow] = useState(false);
  const [yardage, setYardage] = useState(""); // Initialize yardage to 0
  const [activeImage, setActiveImage] = useState(product.imgUrl1); // Corrected variable name from imgShow to activeImage

  const { addToCart } = useCart();
  const handleAddToCart = () => {
    if (yardage && yardage > 0) {
      addToCart(product, yardage);
      setYardage("");
      setShow(false); // Close the modal
    } else {
      alert("Please enter a valid yardage greater than 0.");
    }
  };

  const cardText =
    product.EasyIronOption === "true" ? "Easy Iron" : "Non Easy Iron";

  const hasMultipleSlides = [product.imgUrl4, product.imgUrl5].filter(url => url).length > 0;

  return (
    <div>
      <Card
        onClick={() => setShow(true)}
        border="light"
        className="bg-dark text-white rounded-0.1 shadow"
        style={{
          minWidth: "13rem",
          maxWidth: "13rem",
          flexGrow: 1,
          margin: "1rem",
          minHeight: "14rem",
          flex: "1 1 auto",
        }}
        key={product.productSKU}
      >
        <Card.Img variant="top" src={product.imgUrl1} style={{ width: "100%", height: "auto" }} />
        <Card.Body style={{ height: '65px' }}>
          <Card.Text>
             {product.productSKU}
              <br />
            {product.productName.substring(0, 17) + (product.productName.length > 17 ? "..." : "")}
          </Card.Text>
        </Card.Body>
      </Card>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{product.productName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={5} >
              <Card className="mb-2">
                <ImageMagnifier
                  src={activeImage}
                  alt={product.productName}
                  width="100%"
                  height="auto"
                  magnifierHeight={150}
                  magnifierWidth={150}
                  zoomLevel={2}
                />
              </Card>
              <Row>
                <Col id="multi-item-example" className="carousel slide carousel-multi-item" data-bs-ride="carousel" xs={10}>
                  <Carousel controls={hasMultipleSlides} interval={null} indicators={false}>
                    <Carousel.Item>
                      <div className="d-flex">
                        {[product.imgUrl1, product.imgUrl2, product.imgUrl3].filter(url => url).map((url, index) => (
                          <button className="d-block w-100" onClick={() => setActiveImage(url)} key={index} style={{ border: 'none', background: 'none', padding: 0 }}>
                            <img className="img-fluid" src={url} alt={`Product ${index + 1}`} />
                          </button>
                        ))}
                      </div>
                    </Carousel.Item>
                    {hasMultipleSlides && (
                      <Carousel.Item>
                        <div className="d-flex">
                          {[product.imgUrl4, product.imgUrl5].filter(url => url).map((url, index) => (
                            <button className="d-block w-50" onClick={() => setActiveImage(url)} key={index} style={{ border: 'none', background: 'none', padding: 0 }}>
                              <img className="img-fluid" src={url} alt={`Product ${index + 4}`} />
                            </button>
                          ))}
                        </div>
                      </Carousel.Item>
                    )}
                  </Carousel>
                </Col>
              </Row>
            </Col>
            <Col lg={7} className="mt-5">
              <Row>
                <Col><h6>SKU:</h6></Col>
                <Col><p className="text-muted"><strong>{product.productSKU}</strong></p></Col>
              </Row>
              <Row>
                <Col><h6>Price:</h6></Col>
                <Col><p className="text-muted"><strong>${product.productPrice}</strong></p></Col>
              </Row>
              <Row>
                <Col><h6>Fabric:</h6></Col>
                <Col><p className="text-muted"><strong>{product.productFabric}</strong></p></Col>
              </Row>
              <Row>
                <Col><h6>Color:</h6></Col>
                <Col><p className="text-muted"><strong>{product.productColor}</strong></p></Col>
              </Row>
              <Row>
                <Col><h6>Category:</h6></Col>
                <Col><p className="text-muted"><strong>{product.productCategory}</strong></p></Col>
              </Row>
              <Row>
                <Col><h6>Fabric Pattern:</h6></Col>
                <Col><p className="text-muted"><strong>{product.productFabricPattern}</strong></p></Col>
              </Row>
              <Row>
                <Col><h6>Ironing:</h6></Col>
                <Col><p className="text-muted"><strong>{cardText}</strong></p></Col>
              </Row>
              <Row>
                <Col><h6>Book:</h6></Col>
                <Col><p className="text-muted"><strong>{product.book}</strong></p></Col>
              </Row>
              <Row>
                <Col style={{ color: !product.instock ? "red" : "inherit" }}>
                  {!product.instock && <strong>Out of Stock</strong>}
                </Col>
              </Row>
              {product.instock && (
                <Form onSubmit={(e) => { e.preventDefault(); handleAddToCart(); }}>
                  <input type="hidden" name="product-title" value={product.productName} />
                  <Row className="pb-3">
                    <Col className="d-grid">
                      <InputGroup>
                        <Form.Control
                          type="float"
                          placeholder="Enter yardage"
                          value={yardage}
                          onChange={(e) => setYardage(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to={{
              pathname: "/Fabric/" + product.productSKU,
              state: { product },
            }}
          >
            <Button variant="secondary"> View More</Button>
          </Link>
          {product.instock && (
            <Button variant="primary" onClick={handleAddToCart}>
              Add to Cart
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
