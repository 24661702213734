import React, { useMemo } from "react";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

const ListofOutofStock = ({show, handleCloseModal, productlist}) => {
    const outOfStockProducts = useMemo(() => {
        if (!productlist) return [];
        return productlist.filter(product => !product.instock).sort((a, b) => a.book.localeCompare(b.book));
    }, [productlist]);

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Out Of stock List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>SKU</th>
                            <th>Book</th>
                        </tr>
                    </thead>
                    <tbody>
                        {outOfStockProducts.map(product => (
                            <tr key={product.id}>
                                <td>{product.productName}</td>
                                <td>{product.productSKU}</td>
                                <td>{product.book}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    );
}


export default ListofOutofStock;