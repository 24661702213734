import React, { useMemo, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Row, Col, ListGroup } from 'react-bootstrap'; // Added missing imports
import { db } from "../../config/firebase-config";
import { doc, updateDoc } from "firebase/firestore";

const ManageTopFabric = ({ show, handleCloseModal, productlist }) => {
    const [selectedBook, setSelectedBook] = useState(null); // Added state for selected book

    const distinctBooks = useMemo(() => {
        if (!productlist) return [];
        const bookTitles = productlist.map(book => book.book ? book.book : "undefined");
        const uniqueBooks = [...new Set(bookTitles)];
        return uniqueBooks.sort();
    }, [productlist]);

    const handleSelectBook = (book) => {
        setSelectedBook(book);
    };
    
    
    const updateTopFabric = (id, bookTitle) => {
        // Update all items in the book to be topFabric: false
        productlist.filter(item => item.book === bookTitle).forEach(item => {
            const updateAllItems = doc(db, "OurCollection", item.id);
            updateDoc(updateAllItems, {
                topFabric: false,
            })
            .then(() => {
                console.log("All items in book: " + bookTitle + " are set to topFabric: false");
            })
            .catch((error) => {
                console.error("Error updating book items:", error.message);
            });
        });
        // Set the selected item to be topFabric: true
        const updateproductbook = doc(db, "OurCollection", id);
        updateDoc(updateproductbook, {
            topFabric: true,
        })
        .then(() => {
            alert("Product is updated for book: " + bookTitle);
        })
        .catch((error) => {
            console.error("Error updating book:", error.message);
        });
    };


   
    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Top Fabric</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12} md={6} lg={4}>
                        <ListGroup>
                            {distinctBooks.map((title, index) => (
                                <ListGroup.Item 
                                    key={index} 
                                    action 
                                    onClick={() => handleSelectBook(title)}
                                    active={selectedBook === title}
                                >
                                    {title}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                    <Col sm={12} md={6} lg={8}>
                        {selectedBook && (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Product SKU</th>
                                        <th>Top Fabric</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productlist.filter(book => book.book === selectedBook || (selectedBook === "undefined" && !book.book))
                                        .sort((a, b) => a.productSKU.localeCompare(b.productSKU)) // Sort by productSKU
                                        .map((item, idx) => (
                                        <tr key={idx} onClick={() => updateTopFabric(item.id, selectedBook)}>
                                            <td>{item.productSKU}</td>
                                            <td>{item.topFabric ? 'Yes' : ''}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}


export default ManageTopFabric;