import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import aboutusshow1 from "../components/images/aboutusshow1.jpeg";
//import aboutusshow2 from "../components/images/aboutusshow2.jpeg";
//import aboutusshow3 from "../components/images/aboutusshow3.jpeg";
import ST03 from "../components/images/ST03.jpeg"
import ST05 from "../components/images/ST05.jpeg"
import fabricimg from "../components/images/Fabrics.jpeg"

export const AboutUs = () => {
  return (
    <Container className="AboutUsContainer" style={{  maxWidth: '15in' , minWidth:'4in' }}>
       <Row className="bg-light  mb-1">
        <h1>About Us</h1>
      </Row>
      <Row>
        <Col xs={12} md={6} className="me-auto d-inline-block">
          <Container
            style={{ alignContent: "flex-start", textAlign: "justify" }}
          >
            <Row>
              <h3>SunriseTextile - Meeting All Your Fabric Needs</h3>
              <p>
                At SunriseTextile, we take immense pride in being a premier
                fabric supplier that offers the most extensive variety of
                products and sets new standards for quality and customer service
                in the industry. With a relentless focus on meeting the demands
                of our customers, we strive to surpass our competitors and
                provide an unparalleled fabric sourcing experience.
              </p>
            </Row>
            <Row>
              <h3>Unmatched Variety:</h3>
              <p>
                We understand that every project and customer is unique. That's
                why we have curated an extensive collection of fabrics,
                encompassing a wide range of materials, colors, patterns, and
                textures. Whether you're looking for luxurious silks, durable
                cottons, versatile synthetics, or specialty fabrics,
                SunriseTextile has it all. Our diverse inventory ensures that
                our customers can find the perfect fabric for any application,
                be it fashion, home decor, upholstery, or crafts.
              </p>
            </Row>
          </Container>
        </Col>
        <Col xs={12} md={6}>
          <img
            key="Aboutus1"
            src={ST03}
            //src={aboutusshow1}
            alt="Aboutus1"
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <img
            key="Aboutus2"
            //src={aboutusshow2}
            src={fabricimg}
            alt="Aboutus2"
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Col>
        <Col xs={12} md={6} className="me-auto d-inline-block">
          <Container
            style={{ alignContent: "flex-start", textAlign: "justify" }}
          >
            <Row>
              <h3>Uncompromising Quality:</h3>
              <p>
                Quality is at the core of everything we do. We have established
                strong partnerships with trusted manufacturers and suppliers
                worldwide, allowing us to source fabrics of the highest
                standards. Our stringent quality control procedures ensure that
                every fabric we offer meets or exceeds industry benchmarks. From
                the finest thread counts to impeccable finishes, we are
                committed to delivering fabrics that inspire creativity and
                stand the test of time.
              </p>
            </Row>
            <Row>
              <h3>Dedicated Customer Service:</h3>
              <p>
                At SunriseTextile, our customers are at the heart of our
                business. We believe in building long-lasting relationships by
                providing exceptional customer service. Our knowledgeable and
                friendly team of fabric experts is always ready to assist you in
                selecting the ideal fabric for your project, answering any
                questions you may have, and offering valuable guidance. We
                understand the importance of prompt communication, efficient
                order processing, and timely deliveries to ensure your
                satisfaction.
              </p>
            </Row>
          </Container>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12} md={6} className="me-auto d-inline-block">
          <Container
            style={{ alignContent: "flex-start", textAlign: "justify" }}
          >
            <Row>
              <h3>Meeting the Demand:</h3>
              <p>
                We recognize that our customers rely on us to fulfill their
                fabric requirements promptly and reliably. To meet these
                expectations, we maintain a robust inventory management system
                and strategic partnerships with shipping and logistics
                providers. Our streamlined processes and efficient operations
                enable us to fulfill orders promptly, even during peak seasons.
                We take pride in being the preferred supplier for many
                designers, artisans, manufacturers, and enthusiasts who trust us
                to deliver precisely what they need, when they need it.
              </p>
            </Row>
            <Row>
              <h3>Exceeding Competitors:</h3>
              <p>
                At SunriseTextile, we are not content with just being one of the
                fabric suppliers in the market. We continuously strive to
                surpass our competitors by offering the most extensive variety
                of products, uncompromising quality, dedicated customer service,
                and efficient order fulfillment. Our commitment to excellence
                and our customers' satisfaction sets us apart, making us the
                go-to choice for all fabric needs.{" "}
              </p>
            </Row>

            <Row>
              <p>
                Whether you are a professional designer, a business owner, or a
                passionate crafter, SunriseTextile is here to be your trusted
                fabric partner. Explore our vast collection, experience our
                exceptional service, and discover why we are the preferred
                fabric supplier for those who seek variety, quality, and
                reliability. Partner with us, and let us help you bring your
                creative vision to life with the finest fabrics available in the
                market.
              </p>
            </Row>
          </Container>
        </Col>
        <Col xs={12} md={6}>
          <img
            key="Aboutus3"
            src={ST05}
            //src={aboutusshow3}
            alt="Aboutus3"
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Col>
      </Row>

      <Row>
        <br />
      </Row>
    </Container>
  );
};
