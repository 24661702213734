import React, { useState, useEffect } from "react";
import { db } from "../config/firebase-config";
import { getDocs, collection } from "firebase/firestore";
import { ProductAdminCard as Product } from "../components/productcard/ProductAdminCard";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import AddProductModal from "../components/adminFucntions/AddProductModal"; // Corrected the folder name from 'adminFucntions' to 'adminFunctions'
import Form from "react-bootstrap/Form";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import ManageBooks from "../components/adminFucntions/ManageBooks"
import ListofOutofStock from "../components/adminFucntions/ListofOutofStock"
import ManageTopFabric from "../components/adminFucntions/ManageTopFabric";
import Pagination from "react-bootstrap/Pagination";

export const Admin = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filterColor, setFilterColor] = useState("");
  const [filterFabricPattern, setFilterFabricPattern] = useState("");
  const [filterBook, setfilterBook] = useState("");
  const [filterSKU, setFilterSKU] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Added state for current page
  const itemsPerPage = 30; // Set items per page to 40

  const [showBooks, setShowBooks] = useState(false); // Corrected variable name from 'showbooks' to 'showBooks'
  const [showsOutofstocklist, setshowsOutofstocklist] = useState(false);
  const [showsTopFabric, setshowsTopFabric] = useState(false);
  const fetchPosts = async () => { // Corrected function name from 'fetchPost' to 'fetchPosts'
    const querySnapshot = await getDocs(collection(db, "OurCollection"));
    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setProducts(newData);
  };

  useEffect(() => {
    fetchPosts(); // Corrected function name from 'fetchPost' to 'fetchPosts'
  }, []);

  const handleShowBooks = () => setShowBooks(true); // Corrected function name from 'handleShowbooks' to 'handleShowBooks'
  const handleCloseBooks = () => setShowBooks(false); // Corrected function name from 'handleShowbooks' to 'handleShowBooks'
  const handleCloseOutofStocklist = () => setshowsOutofstocklist (false)
  const handleCloseTopFabric =() => setshowsTopFabric (false)
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleshowsOutofstocklist = () => setshowsOutofstocklist (true)
  const handleshowsTopFabric = () => setshowsTopFabric (true)
  const handleColorFilterChange = (event) => {
    setFilterColor(event.target.value);
  };

  const handleFabricPatternFilterChange = (event) => {
    setFilterFabricPattern(event.target.value);
  };

  const handleBookFilterChange = (event) => {
    setfilterBook(event.target.value);
  };

  const handleSKUFilterChange = (event) => {
    setFilterSKU(event.target.value);
  };

  const filteredProducts = products.filter(
    (product) =>
      (product.productColor === filterColor || filterColor === "") &&
      (product.productFabricPattern === filterFabricPattern || filterFabricPattern === "") &&
      (filterBook === "Others" ? product.book === undefined : product.book === filterBook || filterBook === "") &&
      (product.productSKU.includes(filterSKU) || filterSKU === "")
  );

  // Get distinct colors, fabric patterns, and fabrics from all products
  const allColors = [...new Set(products.map((product) => product.productColor))].sort();
  const allFabricPatterns = [...new Set(products.map((product) => product.productFabricPattern))].sort();
  const allBooks = [...new Set(products.map((product) => product.book || "Others"))].sort();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const currentItems = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  
  return (
    <Container className="AdminContainer" style={{ minHeight: "90vh"  , maxWidth: '15in' , minWidth:'4in'}}>
      
      <Row className="bg-light border mb-1">
        <h1>Admin Page</h1>
      </Row>
      <Row >
        <Col xs={12} sm={12} md={6} lg={3} className="mt-2">
          <Button variant="primary" onClick={handleShowModal}>
            Add Product
          </Button>
          <AddProductModal show={showModal} handleCloseModal={handleCloseModal} />
        </Col>
        <Col xs={12} sm={12} md={6} lg={3} className="mt-2">
          <Button variant="primary" onClick={handleShowBooks}> 
            Manage Books
          </Button>
          <ManageBooks show={showBooks} handleCloseModal={handleCloseBooks} productlist={products} />
        </Col>
        <Col xs={12} sm={12} md={6} lg={3} className="mt-2">
          <Button variant="primary" onClick={handleshowsTopFabric}> 
            Manage Top Fabric
          </Button>
          <ManageTopFabric show={showsTopFabric} handleCloseModal={handleCloseTopFabric} productlist={products} />
        </Col>
        <Col xs={12} sm={12} md={6} lg={3} className="mt-2">
          <Button variant="primary" onClick={handleshowsOutofstocklist}> 
            OutofStock Items
          </Button>
          <ListofOutofStock show={showsOutofstocklist} handleCloseModal={handleCloseOutofStocklist} productlist={products} />
        </Col>

      </Row>
      <Row className="bg-light border m-1 mt-3">
        <Col xs={12} sm={12} md={6} lg={3}>
          <FormLabel>Filter by SKU</FormLabel>
          <FormControl
            type="number"
            value={filterSKU}
            onChange={handleSKUFilterChange}
            placeholder="Enter SKU"
            className="mb-3"
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={3}>
          <FormLabel>Filter by Color</FormLabel>
          <Form.Control
            as="select"
            value={filterColor}
            onChange={handleColorFilterChange}
            className="mb-3"
          >
            <option value="">All Colors</option>
            {allColors.map((color) => (
              <option key={color} value={color}>
                {color}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col xs={12} sm={12} md={6} lg={3}>
          <FormLabel>Filter by Book</FormLabel>
          <Form.Control
            as="select"
            value={filterBook}
            onChange={handleBookFilterChange}
            className="mb-3"
          >
            <option value="">All Books</option>
            {allBooks.map((book) => (
              <option key={book} value={book}>
                {book}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col xs={12} sm={12} md={6} lg={3}>
          <FormLabel>Filter by Fabric Pattern</FormLabel>
          <Form.Control
            as="select"
            value={filterFabricPattern}
            onChange={handleFabricPatternFilterChange}
            className="mb-3"
          >
            <option value="">All Patterns</option>
            {allFabricPatterns.map((pattern) => (
              <option key={pattern} value={pattern}>
                {pattern}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Row fluid="md">
        {currentItems.map((product) => (
          <Product key={product.productSKU} product={product} />
        ))}
      </Row>
      <Row className="justify-content-center">
        <Pagination>
          <Pagination.First onClick={() => { handlePageChange(1); window.scrollTo(0, 0); }} />
          {currentPage > 1 && <Pagination.Prev onClick={() => { handlePageChange(currentPage - 1); window.scrollTo(0, 0); }} />}
          {pageNumbers.slice(Math.max(0, currentPage - 7), Math.min(currentPage + 7, pageNumbers.length)).map((number) => (
            <Pagination.Item key={number} active={number === currentPage} onClick={() => { handlePageChange(number); window.scrollTo(0, 0); }}>
              {number}
            </Pagination.Item>
          ))}
          {currentPage < pageNumbers.length && <Pagination.Next onClick={() => { handlePageChange(currentPage + 1); window.scrollTo(0, 0); }} />}
          <Pagination.Last onClick={() => { handlePageChange(pageNumbers.length); window.scrollTo(0, 0); }} />
        </Pagination>
      </Row>
    </Container>
  );
};
